import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { StripePlugin } from '@vue-stripe/vue-stripe'
import Cloudinary from 'cloudinary-vue'
import VueGtag from 'vue-gtag'
import { ObserveVisibility } from 'vue-observe-visibility'
import router from './router'
import store from './store'
import App from './App.vue'
import '@/libs/acl'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import './registerServiceWorker'

Vue.use(Cloudinary, {
  configuration: {
    cloudName: 'thecompanion',
  },
})
Vue.use(
  VueGtag,
  {
    config: { id: 'UA-107119338-1' },
    params: {
      send_page_view: true,
    },
    appName: 'Omnimenu-Hotel',
    pageTrackerScreenviewEnabled: true,
  },
  router
)

Vue.directive('observe-visibility', ObserveVisibility)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNumber = function toFixedNumber(digits, base) {
  // eslint-disable-next-line no-restricted-properties
  const pow = Math.pow(base || 10, digits)
  return Math.round(this * pow) / pow
}

const stripeLivePK = process.env.VUE_APP_STRIPE_PK
// const stripeTestPK = 'pk_test_51Iui2kH3jXJ6tZjbAQgiu8kT6hACJQHLiJztjAFJwBmlxHT8Cerp3iYFed6P3BW6G0muAPio5ADRFvCjVEnZz9nf00FmYLEnzk'
const options = {
  pk: stripeLivePK,
  apiVersion: '2020-08-27',
}

Vue.use(StripePlugin, options)
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default {
  namespaced: true,
  state: {
    hotel: JSON.parse(localStorage.getItem('hotel') || '{}'),
  },
  getters: {},
  mutations: {
    updateHotels(state) {
      // eslint-disable-next-line no-param-reassign
      // state.hotel = hotel
      localStorage.setItem('hotel', JSON.stringify(state.hotel))
    },
  },
  actions: {
    async updateHotel({ state, commit }, hotel) {
      // eslint-disable-next-line no-param-reassign
      state.hotel = hotel
      commit('updateHotels')
    },
  },
}

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import cart from './cart'
import user from './user'
import hotel from './hotel'
import restaurant from './restaurant'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    cart,
    user,
    hotel,
    restaurant,
  },
  strict: process.env.DEV,
})

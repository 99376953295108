const equal = require('deep-equal')

export default {
  namespaced: true,
  state: {
    added: JSON.parse(localStorage.getItem('added') || '[]'),
  },
  getters: {},
  mutations: {
    updateCart(state) {
      localStorage.setItem('added', JSON.stringify(state.added, null, 2))
    },
  },
  actions: {
    async addItemToCart({ state, commit, dispatch }, cartItem) {
      // console.error(cartItem)
      const alreadyAddedCartItem = state.added.find(
        item =>
          item.id === cartItem.id &&
          item.specialInstruction === cartItem.specialInstruction &&
          equal(item.modifiers, cartItem.modifiers)
      )
      if (alreadyAddedCartItem) {
        await dispatch('updateCartItemQuantity', {
          cartItemId: alreadyAddedCartItem.cartItemId,
          quantity: alreadyAddedCartItem.quantity + cartItem.quantity,
        })
      } else state.added.push(cartItem)
      commit('updateCart')
      // eslint-disable-next-line no-underscore-dangle
      // await this._vm.$http.post('/cart/add', cart)
      // commit('toggleLogin')
    },
    async removeItemFromCart({ state, commit }, cartItemId) {
      state.added = state.added.filter(item => item.cartItemId !== cartItemId)
      commit('updateCart')
    },
    clearCart({ state, commit }) {
      state.added = state.added.filter(() => false)
      commit('updateCart')
    },
    async updateCartItemQuantity({ state, commit }, { cartItemId, quantity }) {
      state.added = state.added.map(item => {
        const updatedItem = item
        if (item.cartItemId === cartItemId) {
          updatedItem.totalPrice = (item.totalPrice / item.quantity) * quantity
          updatedItem.quantity = quantity
        }
        return updatedItem
      })
      commit('updateCart')
    },
  },
}

import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL:
    process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : process.env.VUE_APP_API,
  timeout: 20000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns

export default {
  namespaced: true,
  state: {
    restaurant: JSON.parse(localStorage.getItem('restaurant') || '{}'),
    cartRestaurant: JSON.parse(localStorage.getItem('cartRestaurant') || '{}'),
  },
  getters: {},
  mutations: {
    updateRestaurant(state) {
      if (state.restaurant)
        localStorage.setItem('restaurant', JSON.stringify(state.restaurant))
      else localStorage.removeItem('restaurant')
    },
    updateCartRestaurant(state) {
      if (state.cartRestaurant)
        localStorage.setItem('cartRestaurant', JSON.stringify(state.cartRestaurant))
      else localStorage.removeItem('cartRestaurant')
    },
  },
  actions: {
    async updateRestaurant({ state, commit }, restaurant) {
      // eslint-disable-next-line no-param-reassign
      state.restaurant = restaurant
      commit('updateRestaurant')
    },
    async updatecartRestaurant({ state, commit }, cartRestaurant) {
      // eslint-disable-next-line no-param-reassign
      state.cartRestaurant = cartRestaurant
      commit('updateCartRestaurant')
    },
  },
}

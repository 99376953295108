import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isAdminLoggedIn,
  isUserLoggedIn,
} from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'TheCompanion',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/demo*',
      name: 'demo',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'TheCompanion',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/restaurants',
      name: 'restaurants',
      component: () => import('@/views/Restaurants.vue'),
      meta: {
        pageTitle: '',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Restaurant',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/restaurant',
      name: 'restaurant',
      component: () => import('@/views/Restaurant.vue'),
      meta: {
        pageTitle: '',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Restaurant',
            active: true,
          },
        ],
        backButton: true,
        showCart: true,
        showDeliveringTo: true,
      },
    },
    {
      path: '/order-summary',
      name: 'order-summary',
      component: () => import('@/views/OrderSummary.vue'),
      meta: {
        pageTitle: '',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Order Summary',
            active: true,
          },
        ],
        backButton: true,
        showDeliveringTo: true,
      },
    },
    {
      path: '/review-order',
      name: 'review-order',
      component: () => import('@/views/ReviewOrder.vue'),
      meta: {
        pageTitle: '',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Review Order',
            active: true,
          },
        ],
        backButton: true,
        showDeliveringTo: true,
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/Checkout.vue'),
      meta: {
        pageTitle: '',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Checkout',
            active: true,
          },
        ],
        backButton: true,
        showDeliveringTo: true,
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/Orders.vue'),
      meta: {
        pageTitle: 'Orders',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/Order.vue'),
      meta: {
        pageTitle: 'Order',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Order',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/housekeeping',
      name: 'housekeeping',
      component: () => import('@/views/Housekeeping.vue'),
      meta: {
        pageTitle: 'Housekeeping',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Housekeeping',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/local-activities',
      name: 'local-activities',
      component: () => import('@/views/LocalActivities.vue'),
      meta: {
        pageTitle: 'LocalActivities',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'LocalActivities',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/hotel-services',
      name: 'hotel-services',
      component: () => import('@/views/HotelServices.vue'),
      meta: {
        pageTitle: 'Hotel Services',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Hotel Services',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/local-offers',
      name: 'local-offers',
      component: () => import('@/views/LocalOffers.vue'),
      meta: {
        pageTitle: 'Local Offers',
        resource: 'Auth',
        breadcrumb: [
          {
            text: 'Local Offers',
            active: true,
          },
        ],
        backButton: true,
      },
    },
    {
      path: '/admin/login',
      name: 'admin-login',
      component: () => import('@/views/admin/account/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/admin/hotels/list',
      name: 'admin-hotels-list',
      component: () => import('@/views/admin/hotels/hotels-list/HotelsList.vue'),
      meta: {
        pageTitle: 'Hotels List',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Hotels List',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/admin/hotels/hotels-list/HotelsList.vue'),
      meta: {
        pageTitle: 'Hotels List',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Hotels List',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/admin/hotels/view',
      name: 'admin-hotels-view',
      component: () => import('@/views/admin/hotels/hotel-view/HotelsView.vue'),
      meta: {
        pageTitle: 'Hotel Details',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Hotel Details',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/admin/hotels/edit',
      name: 'admin-hotels-edit',
      component: () => import('@/views/admin/hotels/hotel-edit/HotelsEdit.vue'),
      meta: {
        pageTitle: 'Edit Hotel',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Edit Hotel',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/admin/restaurants/list',
      name: 'admin-restaurants-list',
      component: () =>
        import('@/views/admin/restaurants/restaurants-list/RestaurantsList.vue'),
      meta: {
        pageTitle: 'Restaurant List',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Restaurant List',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/admin/restaurants/view',
      name: 'admin-restaurants-view',
      component: () => import('@/views/admin/restaurants/restaurant-view/RestaurantsView.vue'),
      meta: {
        pageTitle: 'Restaurant Details',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Restaurant Details',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/admin/restaurants/edit',
      name: 'admin-restaurants-edit',
      component: () => import('@/views/admin/restaurants/restaurant-edit/RestaurantsEdit.vue'),
      meta: {
        pageTitle: 'Edit Restaurant',
        resource: 'admin',
        action: 'write',
        breadcrumb: [
          {
            text: 'Edit Restaurant',
            active: true,
          },
        ],
        backButton: false,
        showMenu: true,
      },
    },
    {
      path: '/under-construction',
      name: 'under-construction',
      component: () => import('@/views/UnderConstruction.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () =>
        import(/* webpackChunkName: "ad-lazy" */ '@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isUserLogged = isUserLoggedIn()
  const isAdminLogged = isAdminLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isAdminLogged) return next({ name: 'admin-login' })
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isUserLogged) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach(to => {
  // ? For splash screen
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  Vue.nextTick(() => {
    document.title = to.meta.pageTitle || 'Thecompanion'
  })
})

export default router

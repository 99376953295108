export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('user') || '{}'),
    admin: JSON.parse(localStorage.getItem('admin') || '{}'),
    showLogin: false,
  },
  getters: {
    user: state => {
      const { user } = state
      return user
    },
    showLogin: state => {
      const { showLogin } = state
      return showLogin
    },
    currentUser: state => {
      const { admin } = state
      return admin
    },
  },
  mutations: {
    toggleLogin(state) {
      state.showLogin = !state.showLogin
    },
    UPDATE_USER(state, payload) {
      localStorage.setItem('user', JSON.stringify(payload.user))
      state.user = payload.user
    },
    LOGOUT_USER(state) {
      localStorage.setItem('user', JSON.stringify({}))
      state.user = {}
    },
    UPDATE_ADMIN(state, payload) {
      localStorage.setItem('admin', JSON.stringify(payload.user))
      state.admin = payload.user
    },
    LOGOUT_ADMIN(state) {
      localStorage.setItem('admin', JSON.stringify({}))
      state.admin = {}
    },
  },
  actions: {
    toggleLogin({ commit }) {
      commit('toggleLogin')
    },
  },
}
